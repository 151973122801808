/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Day,
  utils,
} from "@sentisso/react-modern-calendar-datepicker";
import "../../Styles/styles/calendar-style.css";
import { PreferredDateTypeProps } from "../Types/PreferredDateType";
import ActionCard from "../../../library-components/ActionCard/ActionCard";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LeaveNoteInput from "./SubComponents/LeaveNoteInput";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesDesktopServices } from "../../Styles/ServiceDetailsCalendarStyles";
import { SimpleButton } from "../../../library-components/Buttons/Buttons";
import { useLazyQuery, useMutation } from "react-apollo";
import { TicketI } from "../../../../interfaces-and-unionTypes/Interfaces";
import {
  zapierPost,
  submitTicket,
} from "../../../../API/graphql-queries/Queries";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContactDetailsType } from "../../../../interfaces-and-unionTypes/UnionTypes";
import { isMobile } from "react-device-detect";
import CalendarMobile from "./CalendarMobile/CalendarMobile";
import { sendEvent } from "../../../../Helpers/GA";
import Unchecked from "../../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../../assets/illustrations/checkboxChecked.svg";
import axios from "axios";
import { validateEmail } from "../../../../Helpers/stringHelpers";
import TimeslotCalendar from "../../Timeslot/TimeslotCalendar";
import { makeStyles } from "@mui/styles";
import ServiceLoaderDialog from "../../../library-components/Dialogs/ServiceLoaderDialog";
import { abandonedCart, couponCodeCounter, getCouponCode } from "../../../../API/fetch-api/get.coupon.code";


const Scheduler: FC<PreferredDateTypeProps> = (props) => {
  const { contactDetails, errorMsg, setError, gtmDetails } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const [preferred, setPreferredDate] = useState<any>(null);
  const [note, setNote] = useState(state?.andetComment);
  const todayDate = utils("en").getToday();
  const matches = useMediaQuery("(max-width: 350px)");
  const matches2 = useMediaQuery("(min-width: 400px)");
  const matches3 = useMediaQuery("(max-width: 1440px)");
  const [hasAndetService, setHasAndetService] = useState(false);
  useEffect(() => {
    const andetService = state?.basketData?.service_details?.some((service: any) => service?.sa_dsc === "Andet");

    if (andetService) {
      setHasAndetService(true)
    } else {
      setHasAndetService(false)
    }
  }, [state?.basketData])
  const couponCode = useParams();


  const useStylesChecbox = makeStyles({
    root1: {
      textAlign: "left",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: isMobile
        //   ? matches
        //     ? "-32.4vh"
        //     : matches2
        //     ? "-16vh"
        //     : "-22vh"
        //   : matches3
        //   ? "-8.4vh"
        //   : "-3.3vh",
        marginTop: isMobile ? "2.7vh" : "2.3vh",
      },
    },
    root2: {
      textAlign: "left",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: isMobile
        //   ? matches
        //     ? "-8vh"
        //     : matches2
        //     ? "-2vh"
        //     : "-4vh"
        //   : "",
        marginTop: isMobile ? "2.7vh" : "2.3vh",
      },
    },
  });

  const classesChecbox = useStylesChecbox();
  /**
   * @description This object is for testing purposes only
   */
  // const todayDate = {
  //   day: 29,
  //   month: utils("en").getToday().month + 1,
  //   year: utils("en").getToday().year,
  // };
  const monthDays = utils("en").getMonthLength({
    day: 1,
    month: todayDate.month,
    year: todayDate.year,
  });

  const resolveNextDisabledDays = () => {
    const nextDayDisabledArray: Array<Day> = [];
    const disabledDate = new Date(todayDate.year, todayDate.month - 1, todayDate.day + 2);
    nextDayDisabledArray.push({
      day: disabledDate.getDate(),
      month: disabledDate.getMonth() + 1, // Months are 0-based, so add 1
      year: disabledDate.getFullYear(),
    });

    return nextDayDisabledArray;
  };

  const defaultDay = () => {
    const defaultDate = new Date(todayDate.year, todayDate.month - 1, todayDate.day + 3);

    return {
      day: defaultDate.getDate(),
      month: defaultDate.getMonth() + 1, // Months are 0-based, so add 1
      year: defaultDate.getFullYear(),
    };
  };
  const [date, setDate] = useState<any>(defaultDay());

  const [calendarErrorMsg, setCalendarErrorMsg] = useState("");

  const { t } = useTranslation();

  const history = useHistory();

  const classes = useStylesDesktopServices();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [marketingPermission, setMarketingPermission] = useState(true);
  const [notSelectedAcceptTerms, setNotSelectedAcceptTerms] = useState(false);
  const [submitTicketAction, result] = useMutation<Partial<TicketI>>(
    submitTicket,
    {
      onCompleted: (data) => {
        if (data?.submitTicket?.node !== null) {
          dispatch({
            type: "SUBMIT_TICKET_RESPONSE",
            payload: data?.submitTicket,
          });
        }
      },
    }
  );
  const [customTime, setCustomTime] = useState(false);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    }
    if (result?.data?.submitTicket?.message === "Session expired") {
      alert("Session expired");
      history.push("/");
    }
  }, [result?.data]);

  const styles = {
    header: {
      padding: !isMobile ? "4% 2% 0px 2%" : "4% 5.5% 0px",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    content: {},
    actions: undefined,
    container: {
      padding: !isMobile ? "0.5rem" : "",
      width: !isMobile ? "45vw" : "",
    },
    boxWidth: {
      width: !isMobile ? "45vw" : "90%",
    },
  };

  const maximumDate = {
    day: 31,
    month: utils("en").getToday().month + 2,
    year: utils("en").getToday().year,
  };

  const disabledDays: any = [
    ...resolveNextDisabledDays().map((item) => item),
    {
      day: todayDate.day,
      month: todayDate.month,
      year: todayDate.year,
    },
    {
      day: todayDate.day + 1,
      month: todayDate.month,
      year: todayDate.year,
    },
  ];

  useEffect(() => {
    checkCalendar();
  }, [date]);

  const [zapierPostAction, res] = useMutation(zapierPost);

  useEffect(() => {
    if (res?.data === null) {
      alert(res?.error?.message);
    } else {
      if (res?.data?.zapierPost?.node === null) {
        alert(res?.data?.zapierPost?.message);
      }
      if (res?.data?.zapierPost?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [res?.data]);

  useEffect(() => {
    if (state?.selectedTimeslots?.length > 0) {
      setCustomTime(false);
    }
  }, [state])

  useEffect(() => {
    if (customTime)
      dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
  }, [customTime]);


  const makeRequestToZapier = (success: boolean) => {
    zapierPostAction({
      variables: {
        name: contactDetails.fullName,
        email: contactDetails.email,
        address: contactDetails.strAddress1,
        city: contactDetails.city,
        zip_code: contactDetails.zipCode,
        phone_number: contactDetails.phoneNumber,
        price_of_booking: state.basketData?.total_price,
        utm_source: state.googleAdsParams?.utm_source,
        utm_medium: state.googleAdsParams?.utm_medium,
        utm_campaign: state.googleAdsParams?.utm_campaign,
        gclid: state.googleAdsParams?.gclid??'',
        number_plate: state.licence?.node?.licence_plate,
        zendesk_ticket_ID: state.ticket?.node?.ticket_id,
        success: success,
        marketingPermission,
      },
    }).then((res) => {
      console.log(res);
    });
  };


  const callCoupons = async () => {

    const couponCodeResult = await getCouponCode(state?.basketData?.campaign_code);

    if (couponCodeResult) {
      await couponCodeCounter(couponCodeResult?.id);
    }
  }

  const makeRequest = () => {
    sendEvent(
      "basket",
      "submit",
      "send/book",
      state.basketData?.total_price,
      "total_price"
    );
    sendEvent(
      "basket",
      "submit",
      "basket_items",
      state.basketData?.service_details
        ?.map((item: any) => item?.sa_dsc)
        ?.join("; "),
      "basket_items"
    );
    checkCalendar();
    if (
      contactDetails.fullName.trim() !== "" &&
      contactDetails.email.trim() !== "" &&
      validateEmail(contactDetails.email) !== false &&
      contactDetails.strAddress1.trim() !== "" &&
      contactDetails.phoneNumber.length === 8 &&
      contactDetails.phoneNumber.trim() !== "" &&
      contactDetails.zipCode.trim() !== "" &&
      // date !== null &&
      // date.day != todayDate.day
      ((!customTime && state?.selectedTimeslots?.length > 0)
        ||
        (customTime !== null))
    ) {
      setError("");
      setCalendarErrorMsg("");
      const preferredDate =
        preferred.year.toString() +
        "-" +
        preferred.month.toString().padStart(2, "0") +
        "-" +
        preferred.day.toString().padStart(2, "0");

      let noteMessage = "";
      if (state.basketData?.total_price === "0")
        noteMessage = t("ifPrice0") + " / " + note ?? ""
      else if (customTime)
        noteMessage = t("ifCheckedBox") + " / " + note ?? ""
      else
        noteMessage = note || ""


      const ticketObj = {
        flag: "F",
        ticket_id: state.ticket?.node.ticket_id || "",
        address: contactDetails.strAddress1,
        address2: contactDetails.strAddress2,
        commentVal: noteMessage,
        email: contactDetails.email || "",
        fullName: contactDetails.fullName || "",
        phone: contactDetails.phoneNumber || "",
        preferredDate: preferredDate,
        preferredTime: "",
        prefferedTimeSlots: !customTime ? state?.selectedTimeslots : [],
        marketingPermission: marketingPermission ? "1" : "0",
        service_dsc: "",
        service_id: "",
        zip_code: contactDetails.zipCode || "",
        licence_plate: state.licence?.node?.licence_plate || "",
        utmCampaign: state?.googleAdsParams?.utm_campaign,
        utmSource: state?.googleAdsParams?.utm_source,
        utmMedium: state?.googleAdsParams?.utm_medium,
        gclid: state?.googleAdsParams?.gclid??'',
        otherPayer: contactDetails.otherPayer
      }
      submitTicketAction({
        variables: ticketObj,
      }).then((res) => {
        const detailsObject: ContactDetailsType = {
          fullName: contactDetails.fullName,
          email: contactDetails.email,
          strAddress1: contactDetails.strAddress1,
          strAddress2: contactDetails?.strAddress2 || "",
          city: contactDetails.city || "",
          zipCode: contactDetails.zipCode || "",
          phoneNumber: contactDetails.phoneNumber,
          otherPayer: contactDetails.otherPayer
        };
        dispatch({ type: "CONTACT_DETAILS", payload: detailsObject });
        dispatch({ type: "CALENDAR_DATE", payload: date });
        abandonedCart({ 'checkout_step': { ...state.returningCustomer.node, ...state.basketData, ...state?.googleAdsParams, ...ticketObj } })
        makeRequestToZapier(true);



        if (
          res.data?.submitTicket?.node !== null &&
          res?.data?.submitTicket?.node !== undefined
        ) {
          if (
            !state.basketData ||
            !state.basketData?.service_details ||
            state.basketData?.service_details?.length < 1
          ) {
            alert("Add something to basket");
          } else {
            callCoupons();
            history.push("/summary");
            console.log('Purchase')
            console.log(state?.basketData)
          }
        }
      });
    } else {
      checkCalendar();
      setError("Udfyld venligst de krævede felter");
      makeRequestToZapier(false);
      if (
        contactDetails.fullName.trim() === "" ||
        contactDetails.email.trim() === "" ||
        validateEmail(contactDetails.email) === false ||
        contactDetails.strAddress1.trim() === "" ||
        contactDetails.phoneNumber.length < 8 ||
        contactDetails.phoneNumber.trim() === "" ||
        contactDetails.zipCode.trim() === ""
      ) {
        if (state?.selectedTimeslots?.length > 0) {
          setCalendarErrorMsg("");
        }
        setTimeout(() => {
          scrollToTop(0);
        }, 500);
      }
    }
  };
  const scrollToTop = (top: number) => {
    window.scrollTo({ top: top, behavior: "smooth" });
  };

  const checkCalendar = (): boolean => {
    if (
      contactDetails.fullName.trim() !== "" &&
      contactDetails.email.trim() !== "" &&
      validateEmail(contactDetails.email) !== false &&
      contactDetails.strAddress1.trim() !== "" &&
      contactDetails.phoneNumber.length === 8 &&
      contactDetails.phoneNumber.trim() !== "" &&
      contactDetails.zipCode.trim() !== ""
      // date !== null &&
      // date.day == todayDate.day
    ) {
      if (state?.selectedTimeslots?.length < 1) {
        setCalendarErrorMsg("Vælg venligst en dato");
      }
      setTimeout(() => {
        scrollToTop(860);
      }, 500);
      return false;
    }
    if (
      contactDetails.fullName.trim() !== "" &&
      contactDetails.email.trim() !== "" &&
      validateEmail(contactDetails.email) !== false &&
      contactDetails.strAddress1.trim() !== "" &&
      contactDetails.phoneNumber.length === 8 &&
      contactDetails.phoneNumber.trim() !== "" &&
      contactDetails.zipCode.trim() !== "" &&
      // date !== null &&
      // date.day != todayDate.day
      state?.selectedTimeslots?.length > 0
    ) {
      setCalendarErrorMsg("");
      return true;
    }
    return true;
  };

  useEffect(() => {
    if (state.basketData.total_price === "0")
      setCustomTime(true);
  }, [state])

  useEffect(() => {
    setPreferredDate(date);
  }, [date]);

  const myCustomLocale = {
    // months list by order
    months: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "December",
    ],

    // week days by order
    weekDays: [
      {
        name: "Mandag",
        short: "M",
      },
      {
        name: "Tirsdag",
        short: "T",
      },
      {
        name: "Onsdag",
        short: "O",
      },
      {
        name: "Torsdag",
        short: "T",
      },
      {
        name: "Fredag",
        short: "F",
      },
      {
        name: "Lørdag",
        short: "L",
        isWeekend: true,
      },
      {
        name: "Søndag", // used for accessibility
        short: "S", // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 6,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject: any) {
      return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date: any) {
      return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date: any) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit: any) {
      return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  };
  return (
    <ActionCard
      style={styles}
      Header={
        <>
          <Grid
            item
            container
            style={{
              width: "100%",
              padding: !isMobile ? "21px 21px 0 21px" : "",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Grid item container md={12}>
              <Typography
                color={"#353535"}
                fontSize={18}
                fontFamily={"Poppins, Bold"}
                fontWeight={800}
              >
                {t("title")}
              </Typography>
            </Grid>
          </Grid>
          {customTime &&
            <Grid
              item
              container
              style={{
                width: "100%",
                padding: !isMobile ? "21px 21px 0 21px" : "",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item container md={12} style={{ paddingBottom: "3%" }}>
                <Typography
                  color={"#353535"}
                  fontSize={18}
                  fontFamily={"Poppins, Bold"}
                  fontWeight={800}
                >
                  {t("sendButtonWithoutPrice")}
                </Typography>
              </Grid>
            </Grid>}
        </>
      }
      Content={
        <Grid item container>
          <>
            {!isMobile ? (
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // padding: "0px 38px 0 38px",
                }}
              >
                {/* <Calendar
                calendarTodayClassName="responsive-calendar__today"
                calendarClassName="responsive-calendar"
                // shouldHighlightWeekends
                onChange={setDate}
                value={date}
                disabledDays={disabledDays}
                locale={myCustomLocale}
                minimumDate={todayDate}
                maximumDate={maximumDate}
                disabledWeekDays={[5, 6]}
              /> */}
                <TimeslotCalendar
                  elevation={0}
                  numberOfDays={5}
                  date={new Date()}
                  onSelectTimeslot={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                  timeSlots={[]}
                  selectLimit={1}
                  zipCode={contactDetails.zipCode}
                  city={contactDetails.city}
                  address={contactDetails.strAddress1}
                />
              </Grid>
            ) : (
              // <CalendarMobile
              //   setDate={setDate}
              //   date={date}
              //   disabledDays={disabledDays}
              //   myCustomLocale={myCustomLocale}
              //   todayDate={todayDate}
              //   maximumDate={maximumDate}
              // />
              <TimeslotCalendar
                elevation={0}
                numberOfDays={5}
                date={new Date()}
                onSelectTimeslot={function (): void {
                  throw new Error("Function not implemented.");
                }}
                timeSlots={[]}
                selectLimit={1}
                zipCode={contactDetails.zipCode}
                city={contactDetails.city}
                address={contactDetails.strAddress1}
              />
            )}

            <Grid
              item
              container
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {calendarErrorMsg.trim() !== "" ? (
                <FormHelperText
                  style={{
                    paddingTop: "1%",
                    color: "#d32f2f",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {calendarErrorMsg}
                </FormHelperText>
              ) : null}
            </Grid>
          </>
          {/* customTimeCheckBox */}
          {/* hide checkbox */}
          {false && state.basketData.total_price !== "0" &&
            <Grid
              item
              container
              md={12}
              className={classes.flexCenter}
              style={{ paddingTop: "1.5%", paddingBottom: "3%" }}
            >
              <Grid
                item
                container
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: isMobile ? "left" : "center",

                  paddingLeft: isMobile ? "2%" : "4%",
                  paddingRight: isMobile ? "2%" : "9%",
                }}
              >
                <Grid>
                  <FormControlLabel
                    sx={{ display: "flex" }}
                    control={
                      <div style={{ display: "flex", alignItems: "center", marginTop: '-8px' }}>
                        <Checkbox
                          style={{
                            color: "#8D88FF",
                          }}
                          icon={<img src={Unchecked} />}
                          checkedIcon={<img src={Checked} />}
                          onChange={(e: any) => {
                            setCustomTime(!customTime);

                          }}
                          checked={customTime}
                        />
                      </div>
                    }
                    label={
                      <Typography fontWeight={600} fontStyle={'italic'} fontSize={12}>
                        {t("notSuitableTime")}

                      </Typography>
                    }
                    className={classesChecbox.root1}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid
            item
            container
            md={12}
            className={classes.flexCenter}
            style={{
              paddingTop: "3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasAndetService && note === "" &&
              <Typography fontSize={14} style={{ marginTop: "1rem", marginBottom: "1rem", float: "left" }} color={"red"}>
                Tilføj venligst en kommentar først for at fortsætte *
              </Typography>}
            <LeaveNoteInput note={note} setNote={setNote} />
          </Grid>
        </Grid>
      }
      Actions={
        <Grid
          item
          container
          md={12}
          className={classes.flexCenter}
          style={{ paddingTop: "1.5%", paddingBottom: "3%" }}
        >
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: isMobile ? "start" : "center",

              paddingLeft: isMobile ? "2%" : "4%",
              paddingRight: isMobile ? "2%" : "9%",
            }}
          >
            <Grid>
              {/* <Typography fontSize={10}>{t("sendRequestWarning")}</Typography> */}
              <FormControlLabel
                sx={{ display: "table" }}
                control={
                  <div style={{ display: "table-cell" }}>
                    <Checkbox
                      style={{
                        color: "#8D88FF",
                      }}
                      icon={<img src={Unchecked} />}
                      checkedIcon={<img src={Checked} />}
                      onChange={(e: any) => {
                        setMarketingPermission(!marketingPermission);
                      }}
                      checked={marketingPermission}
                    />
                  </div>
                }
                label={
                  <Typography fontWeight={400} fontSize={12}>
                    {t("acceptConditions1")}
                    <span>
                      <a
                        href="https://boxnow.dk/personoplysninger/"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        style={{ color: "#00F" }}
                      >
                        persondatapolitik
                      </a>
                      <span> og </span>
                      <a
                        href="https://boxnow.dk/generelle-betingelser/"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        style={{ color: "#00F" }}
                      >
                        betingelserne
                      </a>
                    </span>
                    {t("acceptConditions1Continued")}
                    <span>
                      <a
                        href="mailto:hej@boxnow.dk"
                        style={{ color: "#00F" }}
                      >
                        hej@boxnow.dk
                      </a>
                    </span>
                  </Typography>
                }
                className={classesChecbox.root1}
              />
              <FormControlLabel
                sx={{ display: "table" }}
                control={
                  <div style={{ display: "table-cell" }}>
                    <Checkbox
                      style={{
                        color: "#8D88FF",
                      }}
                      icon={<img src={Unchecked} />}
                      checkedIcon={<img src={Checked} />}
                      onChange={(e: any) => {
                        setAcceptTerms(!acceptTerms);
                        setNotSelectedAcceptTerms(false);
                      }}
                      checked={acceptTerms}
                    />
                  </div>
                }
                label={
                  <Typography fontWeight={400} fontSize={12}>
                    {t("acceptConditions2")}{" "}
                    <a
                      href="https://boxnow.dk/generelle-betingelser/"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{ color: "#00F" }}
                    >
                      vilkår
                    </a>{" "}
                    {t("acceptConditions3")}
                  </Typography>
                }
                className={classesChecbox.root2}
              />

              <FormHelperText
                style={{
                  paddingTop: "1%",
                  color: "#d32f2f",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography fontSize={12} textAlign="center">
                  {errorMsg}
                </Typography>
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "3%",
              paddingBottom: "5%",
            }}
          >{hasAndetService && note !== "" &&
            <SimpleButton
              buttonId="checkout-button-send"
              // loading={result?.loading}
              style={{
                textTransform: "none",
                width: "220px",
                maxWidth: "220px",
                height: 47,
                fontSize: 18,
                fontWeight: 800,
              }}
              onClick={() => {
                if (!acceptTerms) {
                  setNotSelectedAcceptTerms(true);
                } else {
                  makeRequest();
                }
              }}
            >
              {(state.basketData.total_price === "0" || customTime) ? (<>
                {t("sendButtonWithoutPrice")}

              </>) : (<>
                {t("sendButton")}
              </>)}

            </SimpleButton>
            }
            {!hasAndetService &&
              <SimpleButton
                buttonId="checkout-button-send"
                // loading={result?.loading}
                style={{
                  textTransform: "none",
                  width: "220px",
                  maxWidth: "220px",
                  height: 47,
                  fontSize: 18,
                  fontWeight: 800,
                }}
                onClick={() => {
                  if (!acceptTerms) {
                    setNotSelectedAcceptTerms(true);
                  } else {
                    makeRequest();
                  }
                }}
              >
                {(state.basketData.total_price === "0" || customTime) ? (<>
                  {t("sendButtonWithoutPrice")}

                </>) : (<>
                  {t("sendButton")}
                </>)}

              </SimpleButton>
            }
            {state.basketData?.total_price?.trim() !== undefined && state.basketData?.total_price?.includes("*") &&
              <Grid item md={12} fontFamily={"Poppins"} fontSize={14} style={{ margin: "2rem" }}>
                <Typography fontFamily={"Poppins"} fontSize={14} color={"#1A1D1E"}>
                  *Bemærk at der på din bil kan være behov for forskellige reservedele alt efter udstyrsvarianten. En af vores dygtige teknikkere, vil snarest vurdere om vores system har valgt korrekt, og tager fat i dig hurtigst muligt, hvis vi vurderer, at der skal bruges andre reservedele, hvilket kan have betydning for den angivne pris.
                </Typography>
              </Grid>
            }
            <Grid>
              {notSelectedAcceptTerms && (
                <FormHelperText
                  style={{
                    paddingTop: "1%",
                    color: "#d32f2f",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography fontSize={12} textAlign="center">
                    {t("errorMessageNotAccepted")}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <ServiceLoaderDialog
            open={result?.loading}
            close={() => {
              return;
            }}
            title={t("submitLoaderMessage")}
          />
        </Grid>
      }
    />
  );
};

export default Scheduler;
